import { Center, Image } from '@chakra-ui/react';
import { useColorModeValue, HStack, Flex, Select, Box} from '@chakra-ui/react';
import logo from 'logo-teal.png';
import UserMenu from 'components/UserMenu';
import NotificationsButton from 'components/NotificationsButton';
import './topbar.scss';
import { useMojoFetch } from 'api/useMojoFetch';
import { useAppDispatch } from 'store/hooks';
import { selectGroupId } from 'store/reducers/appSlice';
import { ChangeEvent, useState } from 'react';
import { store } from 'store/store';
import { ToggleColorModeButton } from 'components/Button/ToggleColorMode';

export function TopBar() {
  const [defaultValue, setDefaultValue] = useState(
    store.getState().app.selectedGroupId
  );
  const dispatch = useAppDispatch();
  const bg = useColorModeValue('white.100', '#282828');
  const fontColor = useColorModeValue('gray.800', 'white');
  

  const { data: groupsData, error:getError } = useMojoFetch('/api/v1/groups/', 'get');

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setDefaultValue(event.target.value);
    dispatch(selectGroupId(event.target.value));
  };

  return (
    <Box className='mojo-top-bar' bg={bg}>
      <Flex className='mojo-top-bar-content'>
        <HStack className='mojo-top-bar-left' spacing={10}>
          <Box className='mojo-logo-wrapper'>
            <Image className='branding-logo' p={2} src={logo} alt='Mojo logo' />
          </Box>
        </HStack>
        <HStack className='mojo-top-bar-right' spacing={10}>
          {getError.message === '' ? (<Select
            value={defaultValue}
            className='organization-select'
            bg={bg}
            placeholder='Group'
            _placeholder={{ color: fontColor, opacity: 1 }}
            onChange={(e) => handleChange(e)}
          >
            <option key='All' value='All'>
              All
            </option>
            {groupsData.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </Select>) : (<Center>Groups get failed</Center>)}
          <Flex className='top-bar-right-actions'>
            {/* <ToggleColorModeButton /> */}
            <NotificationsButton />
            <UserMenu />
          </Flex>
        </HStack>
      </Flex>
    </Box>
  );
}
