import { DataTableSkeleton } from 'components/DataTable/Skeleton';
import './GroupsTable.scss';
import { Box, Button, Flex, useToast } from '@chakra-ui/react';
import { DataTable } from 'components/DataTable/DataTable';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { ButtonDanger } from 'components/ButtonDanger/ButtonDanger';
import { useEffect, useState } from 'react';
import SelectFilter from 'components/DataTable/Filters/SelectFilter';
import { useMojoEffect } from 'api/useMojoEffect';

const columns = [
  {
    Header: 'GROUP',
    accessor: 'name',
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'Group',
          className: 'fix-select-2x-larger',
        }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  {
    Header: 'AGENCY',
    accessor: 'agency',
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'Agency',
          className: 'fix-select-2x-larger',
        }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
];

export default function GroupsTable(props) {
  const toast = useToast();
  const [tableData, changeTable] = useState([] as any);
  const { runWithId: deleteTheGroup } = useMojoEffect(
    '/api/v1/groups/',
    'delete'
  );

  useEffect(() => {
    processTable(props.data);
  }, [props]);

  const processTable = (data) => {
    if (data !== undefined) {
    var result = [] as any;   
    data.forEach((d) => {
      result.push({
        id: d.id,
        name: d.name,
        agency: d.agency !== null ? d.agency.name : '',
      });
    });
    changeTable(result);
  } else {
    changeTable([]);
  }
  };

  async function deleteGroup(groupId: string) {
    const [body, error] = await deleteTheGroup(groupId);
    if (error === null) {
      toast({
        title: 'Group deleted',
        status: 'success',
      });
    } else {
      toast({
        title: 'Deletion failed',
        status: 'error',
      });
    }

    if (error === null) {
      var tableData2: any = [];
      tableData.forEach((f) => {
        if (f.id !== groupId) {
          tableData2.push(f);
        }
      });
      changeTable(tableData2);
    }
  }

  return (
    <div className='datatable-main datatable-controls'>
      <Box w='60%'>
        {tableData ? (
          <DataTable data={tableData} columns={columns} showFilter chooseRows>
            {{
              rowFooter: (row: any) => (
                <Flex gap={1}>
                  <Button variant='mojoPrimaryGhost' onClick={() => {}}>
                    <EditIcon onClick={() => props.editGroup(row.id)} />
                  </Button>
                  <ButtonDanger onClick={() => deleteGroup(row.id)}>
                    <DeleteIcon />
                  </ButtonDanger>
                  {/*<IconButton
                    mt='8px'
                    colorScheme='blue'
                    size='xs'
                    aria-label='add users'
                    icon={<HiMiniUsers />}
                    onClick={() => props.addEditUsers(row.id)}
                  />*/}
                </Flex>
              ),
            }}
          </DataTable>
        ) : (
          <DataTableSkeleton columns={columns}>
            {{ rowFooter: () => <></> }}
          </DataTableSkeleton>
        )}
      </Box>
    </div>
  );
}
