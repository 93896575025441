import {
  Box,
  Center,
  Flex,
  VStack,
  Image,
  Text,
  useToast,
  Heading,
  Progress,
  Button,
  Spinner,
  useDisclosure,
  Table as ChakraTable,
  TableContainer,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { usePapaParse } from 'react-papaparse';
import { Table } from 'antd';
import { useMojoEffect } from 'api/useMojoEffect';
import './Uploader.scss';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const baseStyle = {
  flex: 1,
  display: 'flex',
  height: '135px',
  align: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 5,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

type BFile = {
  file: any;
  tenantId: string;
};
export function Uploader({
  file_header,
  columns,
  maxFiles,
  file_type,
  location_id,
  location_name,
  vendors,
  departments,
  channels,
  types,
  media,
  objectives,
  audiences,
}) {
  //const { data: vendors, error: vendorFetchError } = useMojoFetch('api/v1/vendors/all', 'get');
  const { runWithId: getPresignedUrl } = useMojoEffect(
    `/api/v1/app/signedUrl/`,
    'get'
  );
  const [isDropping, setIsDropping] = useState(false);
  const [csv_data, setData] = useState([]);
  const toast = useToast();
  const [files, setFiles] = useState<BFile[]>([]);
  const [progress, setProgress] = useState(0);
  const { readString } = usePapaParse();
  const [errorList, setErrorList] = useState<string[]>([]);

  // Pad a number to 2 digits
  const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
  // Get timezone offset in ISO format (+hh:mm or -hh:mm)
  const getTimezoneOffset = (date) => {
    const tzOffset = -date.getTimezoneOffset();
    const diff = tzOffset >= 0 ? '+' : '-';
    return diff + pad(tzOffset / 60) + ':' + pad(tzOffset % 60);
  };

  const toISOStringWithTimezone = (date: Date) => {
    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      getTimezoneOffset(date)
    );
  };

  function renameFile(name: string): string {
    const file = files.find((f) => f.file.name === name);
    if (file === undefined) {
      throw new Error(`File named ${name} not found in budgets list`);
    }
    const new_filename =
      file_type.substring(0, file_type.length - 1) +
      '_' +
      file.tenantId +
      '_' +
      toISOStringWithTimezone(new Date()) +
      '.csv';
    return new_filename;
  }

  async function uploadEverything() {
    setProgress(1); //just to trigger the UPLOADING label
    const totalFileSize = files.reduce(
      (partialSum, a) => partialSum + a.file.size,
      0
    );
    let uploadedFileSize = 0;
    for (const f of files) {
      const [resultURL, errors] = await getPresignedUrl(
        renameFile(f.file.name) + `?file_type=${file_type}`
      );
      if (errors !== null) {
        toast({
          title: `Could not upload ${f.file.name}`,
          status: 'error',
        });
      } else {
        try {
          //upload file
          uploadFile(resultURL.url, f.file);
        } catch (err) {
          console.error(err);
          toast({
            title: `Could not upload ${f.file.name}`,
            status: 'error',
          });
        }
      }
      uploadedFileSize += f.file.size;
      setProgress((100 * (uploadedFileSize + 0.0)) / totalFileSize);
    }
    setProgress(100);
    await delay(1000);
    setProgress(0);
    setFiles([]);
    setData([]);
  }

  function uploadFile(url, file) {
    const fileData = new FormData();
    fileData.append('file', file);
    var client = new XMLHttpRequest();
    client.open('PUT', url, false);
    client.send(file);
  }

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { 'text/csv': [] },
      maxFiles: maxFiles,
      multiple: false,
      validator: myValidator,
      onDropAccepted: (e) => {
        setIsDropping(false);
      },
      onDropRejected: (e) => {
        setIsDropping(false);
      },
      onError: (e) => {
        setIsDropping(false);
      },

      onDrop: (acceptedFiles) => {
        setIsDropping(true);
        //Parse contents
        acceptedFiles.forEach((file) => {
          var reader = new FileReader();
          reader.readAsText(file);
          reader.onloadend = function (e) {
            parseContents(file, reader.result);
          };
        });
      },
    });

  function parseContents(myFile, contents) {
    readString(contents.trim(), {
      header: true,
      worker: false,
      transformHeader: convertCase,
      complete: async (records) => {
        if (records.errors !== undefined && records.errors.length > 0) {
          records.errors.forEach((e) => {
            errorList.push(`Row ${e.row}: ${e.message}`);
          });
          setErrorList([...errorList]);
          return;
        }

        const result = await parseRecords(records.data);
        if (result !== null) {
          const new_list = [...files];
          new_list.push({ file: myFile, tenantId: location_id });
          setFiles(new_list);
        }
      },
    });
  }
  const { isOpen, onOpen, onClose } = useDisclosure();

  const parseRecords = async (tableData) => {
    if (
      location_id === undefined ||
      location_id === null ||
      location_id === ''
    ) {
      return null;
    }

    //1. Check for missing columns
    const firstRow = tableData[0];
    columns.forEach((c) => {
      if (firstRow[c.title] === undefined) {
        errorList.push(`Column '${c.title}' missing`);
      }
    });

    if (errorList.length === 0) {
      //2. Check for additional columns
      Object.keys(firstRow).forEach((k) => {
        if (!file_header.includes(k)) {
          errorList.push(`Pls remove column '${k}'.`);
        }
      });

      let index = 2;
      for (const row of tableData) {
        //3. Check that dealer is the same as the one selected in Budgets/Goals component
        if (row.Location.trim() !== location_name) {
          errorList.push(
            `Row ${index}: Found dealer  '${
              row.Location.trim() === '' ? '<empty>' : row.Location.trim()
            }' instead of '${location_name}'.`
          );
        }

        //4.Check that the file concerns a single year
        const basic_year = tableData[0].Year.trim();
        if (row.Year.trim() !== basic_year) {
          errorList.push(
            `Row ${index}: Found year '${
              row.Year.trim() === '' ? '<empty>' : row.Year.trim()
            }' instead of '${basic_year}'.`
          );
        }
        //5. Check if departments exist
        doError(departments, index, 'Department', 'Dept', row);

        //6. Check if channel types exist
        doError(types, index, 'Type', 'Type', row);
        if (file_type === 'budgets') {
          //7. Check if vendors exists
          const myVendors = vendors.map((v) => v.unique_name);
          const partner = row.Partner.toLowerCase().trim().replace(/\s+/g, '');
          if (!myVendors.includes(partner)) {
            errorList.push(
              `Row ${index}: Partner ${
                partner === '' ? '<empty>' : `'${partner}'`
              } does not exist.`
            );
          }
          //8. Check if channels exist
          doError(channels, index, 'Channel', 'Channel', row);
          doError(media, index, 'Medium', 'Medium', row);
          doError(objectives, index, 'Objective', 'Objective', row);
          doError(audiences, index, 'Audience', 'Audience', row);
        }
        index++;
      }
    }
    if (errorList.length) {
      setErrorList([...errorList]);
      onOpen();
      return null;
    }
    setData(csv_data.concat(tableData));
    return location_id;
  };

  function doError(
    list: any[],
    index: number,
    label: string,
    property: string,
    row: any
  ) {
    let errorsExist = false;
    const myList = list.map((d) =>
      d.name.toLowerCase().trim().replace(/\s+/g, '')
    );
    const node = row[property].toLowerCase().trim().replace(/\s+/g, '');
    if (!myList.includes(node)) {
      errorList.push(
        `Row ${index}: ${label} ${
          node === '' ? '<empty>' : `'${node}'`
        } does not exist.`
      );
    }
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  function myValidator(myFile) {
    //1. check that we are under the max. file limit
    if (myFile.kind && files.length === maxFiles) {
      toast({
        title: `You cannot add more than ${maxFiles} files`,
        status: 'error',
      });
      return { message: '', code: '' };
    }
    if (files.length === maxFiles) {
      return { message: '', code: '' };
    }
    //2. Check if file already exists
    if (!myFile.kind && files.some((f) => f.file.name === myFile.name)) {
      toast({
        title: `Duplicate file`,
        status: 'error',
      });
      return { message: '', code: '' };
    }

    return null;
  }

  return (
    <>
      <Box className='uploader--container'>
        {isDropping && (
          <Center>
            <Spinner />
          </Center>
        )}

        <div {...getRootProps({ style })}>
          <VStack>
            <Flex>
              {files.map((f, i) => (
                <VStack mr='30px' key={i}>
                  <Center>
                    <Image
                      boxSize='50px'
                      src='https://upload.wikimedia.org/wikipedia/commons/c/c6/.csv_icon.svg'
                      alt='csv icon'
                    />
                  </Center>
                  <Center>
                    <Text style={{ fontSize: 'var(--chakra-fontSizes-2xs)' }}>
                      {f.file.name}
                    </Text>
                  </Center>
                </VStack>
              ))}
            </Flex>
            <Box>
              <Center>
                <input {...getInputProps()} />
                <p>
                  Drag 'n' drop some files here, or click to select files for
                  tenant {location_name}
                </p>
              </Center>
            </Box>
          </VStack>
        </div>
      </Box>
      {csv_data.length > 0 && (
        <Flex className='upload-progress--container'>
          <Text className='progress-text'>Progress</Text>
          <Progress
            className='progress-bar'
            value={progress}
            width='100%'
            colorScheme='cyan'
          />
        </Flex>
      )}
      {csv_data.length > 0 && (
        <Box className='uploader-preview--container'>
          <Box className='preview-content'>
            <Heading className='preview-heading'>UPLOAD PREVIEW</Heading>
            <Table
              className='preview-table'
              columns={columns}
              dataSource={csv_data}
              scroll={{ x: 1000, y: 200 }}
            />
          </Box>
          <Flex className='uploader-preview--footer'>
            <Button
              className='reset-button'
              size='sm'
              colorScheme='cyan'
              color='white'
              onClick={() => {
                setFiles([]);
                setData([]);
              }}
            >
              RESET
            </Button>
            <Button
              className='complete-upload-button'
              size='sm'
              colorScheme='cyan'
              color='white'
              onClick={async () => await uploadEverything()}
            >
              COMPLETE UPLOAD
            </Button>
          </Flex>
        </Box>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bg='white'
          maxWidth='30%'
          maxHeight='70%'
          overflowY='auto'
        >
          <ModalHeader>Errors during upload</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <ChakraTable variant='striped' colorScheme='teal' size='sm'>
                <Tbody>
                  {errorList.map((e) => (
                    <Tr>
                      <Td>{e}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </ChakraTable>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme='blue'
              mr={3}
              onClick={() => {
                setErrorList([]);
                onClose();
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
  function convertCase(header: string, index: number): string {
    if (header.toLowerCase().trim().includes('coop eligible')) {
      return 'COOP Eligible';
    } else if (header.toLowerCase().trim().includes('coop percentage')) {
      return 'COOP Percentage';
    } else {
      const trimmed = header.trim();
      return `${trimmed[0].toUpperCase()}${trimmed.substring(1).toLowerCase()}`;
    }
  }
}
