import {
  FormLabel,
  FormControl,
  Box,
  Grid,
  Switch,
  Select,
  Flex,
  Button,
  Card,
  useColorModeValue,
  FormErrorMessage,
  InputRightElement,
  InputGroup
} from '@chakra-ui/react';



import { FloatingLabelInput } from '../../../components/FloatingLabelInput';
import { useEffect, useState } from 'react';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { useMojoEffect } from 'api/useMojoEffect';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

export default function UserForm(props) {
  
  const [password, setPassword] = useState('')
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');
  const [strengthMessage, setStrengthMessage] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const toggleShowPass1 = () => setShowPass1(!showPass1);
  const toggleShowPass2 = () => setShowPass2(!showPass2);

  const [activeFlag, setActiveFlag] = useState(false);
  const [blockFlag, setBlockFlag] = useState(false);

  const [myRoleOptions, setRoleOptions] = useState([] as any);

  const { runWithId: getEntities } = useMojoEffect(`/api/v1/`, 'get');
  const [defaultRoleValue, setRoleValue] = useState('');
  const [defaultOption, setOption] = useState('');

  const checkPassword = (password: string) => {
    let message = '';
    let invalid = false;

    if (pass1.length < 10) {
      message = 'Password must be at least 10 characters.';
      invalid = true;
    } else if (!/[A-Z]/.test(pass1)) {
      message = 'Password must include at least one uppercase letter.';
      invalid = true;
    } else if (!/[a-z]/.test(pass1)) {
      message = 'Password must include at least one lowercase letter.';
      invalid = true;
    } else if (!/[0-9]/.test(pass1)) {
      message = 'Password must include at least one number.';
      invalid = true;
    }
      else if (!/[!,@,#,$,%,^,&,*]/.test(pass1)) {
      message = 'Password must include at least one special character.';
      invalid = true;
    }
    else {
      message = 'Strong password!';
      invalid = false;
    }

    setStrengthMessage(message);
    setIsInvalid(invalid);
  };

  const passwordMatch = () => {
    if (pass1 !== pass2) {
      setIsInvalid(true);
      setStrengthMessage('Passwords must match.');
    } else {
      setIsInvalid(false);
      setStrengthMessage((prev) => prev.includes('must match') ? '' : prev);
    }
  }


  useEffect(() => {
    const doOptions = async () => {
      if (props?.defaultRoleValue?.label.includes('Organization')) {
        const [data, errors] = await getEntities('organizations');
        if (errors === null) {
          setRoleOptions(data);
          props.setRoleOptions(data);
          setOption(props?.defaultRoleOption?.value);           
        }
        return;
      }
      if (props?.defaultRoleValue?.label.includes('Agency')) {
        const [data, errors] = await getEntities('agencies');
        if (errors === null) {
          setRoleOptions(data);
          props.setRoleOptions(data);
          setOption(props?.defaultRoleOption?.value);
        }
        return;
      }
      if (props?.defaultRoleValue?.label.includes('Group')) {
        const [data, errors] = await getEntities('groups');
        if (errors === null) {
          setRoleOptions(data);
          props.setRoleOptions(data);
          setOption(props?.defaultRoleOption?.value);
        }
        return;
      }
      if (props?.defaultRoleValue?.label.includes('Client')) {
        setRoleOptions([]);
        props.setRoleOptions([]);
        setOption('');        
        return;
      }
      setRoleOptions([]);
    };
    if (props.defaultRoleValue) {
      setRoleValue(props.defaultRoleValue.value);
      props.setRole(props.defaultRoleValue.label);
    }
    setActiveFlag(props.activeFlag);
    setBlockFlag(props.blockFlag);
    doOptions();
  }, []);

  const handleOptionChange = async (event) => {
    setOption(event.target.value);
  };
  const handleRoleChange = async (event) => {
    setRoleValue(event.target.value);   
    const foo = props.roles.find((x) => x.Id === event.target.value);
     props.setRole(foo.name);
    if (foo.name.includes('Organization')) {
      const [data, errors] = await getEntities('organizations');
      if (errors === null) {
        setRoleOptions(data);
        props.setRoleOptions(data);
      }
      return;
    }
    if (foo.name.includes('Agency')) {
      const [data, errors] = await getEntities('agencies');
      if (errors === null) {
        setRoleOptions(data);
        props.setRoleOptions(data);
      }
      return;
    }
    if (foo.name.includes('Group')) {
      const [data, errors] = await getEntities('groups');
      if (errors === null) {
        setRoleOptions(data);
        props.setRoleOptions(data);
      }
      return;
    }
    if (foo.name.includes('Client')) {
      setRoleOptions([]);
      props.setRoleOptions([]);
      /*
      const [data, errors] = await getEntities('clients');
      if (errors === null) {
        setRoleOptions(data);
        props.setRoleOptions(data);
      }
      */
      return;
    }
  };

  const handleChange1 = (event) => {
    setPass1(event.target.value);
    checkPassword(pass1)
  };

  const handleChange2 = (event) => {
    setPass2(event.target.value);
    checkPassword(pass2);
  };


  const bg = useColorModeValue('white.100', 'black.100');
  const color = useColorModeValue('black.700', 'white.100');

  return (
    <>
      <Card p={18} bg={bg} w={800}>
        <Box my={4} textAlign='left'>
          <Grid
            templateColumns='repeat(2, 1fr)'
            columnGap='12px'
            rowGap='15px'
            marginTop='4rem'
            marginLeft='2rem'
          >
            <FormControl>
              <FormLabel w='10rem'>USER DETAILS</FormLabel>
            </FormControl>
            <FormControl display='flex'>
              <FormLabel>Active?</FormLabel>
              <Switch
                id='active'
                name='activeFlag'
                size='md'
                isChecked={activeFlag}
                {...props.register('activeFlag')}
                onChange={(e) => {
                  setActiveFlag(e.target.checked);
                }}
              />
              <FormLabel ml='2rem'>Blocked?</FormLabel>
              <Switch
                id='block'
                name='blockFlag'
                size='md'
                isChecked={blockFlag}
                {...props.register('blockFlag')}
                onChange={(e) => {
                  setBlockFlag(e.target.checked);
                }}
              />
            </FormControl>
            <FloatingLabelInput
              placeholder='First Name'
              isRequired={true}
              {...props.register('firstName')}
              type='text'
            />

            <FloatingLabelInput
              placeholder='Last Name'
              isRequired={true}
              {...props.register('lastName')}
              type='text'
            />

            <FloatingLabelInput
              placeholder='Email'
              isRequired={true}
              {...props.register('email')}
              type='text'
            />

            <FloatingLabelInput
              placeholder='Phone'
              isRequired={true}
              {...props.register('phone')}
              type='text'
            />

            <FormControl
              isRequired
              variant='floating'
              onChange={(e) => handleRoleChange(e)}
            >
              <Select
                value={defaultRoleValue}
                placeholder='Select...'
                height='45px'
                borderRadius='5'
                bg={bg}
                color={color}
                border='gray.200'
                {...props.register('userRole')}
              >
                {props.roles.map((r) => (
                  <option value={r.Id}>{r.name}</option>
                ))}
              </Select>
              <FormLabel style={{ paddingRight: '8px' }}>Role</FormLabel>
            </FormControl>

            {myRoleOptions.length > 0 && (
              <FormControl isRequired onChange={handleOptionChange}>
                <Select
                  value={defaultOption}
                  height='45px'
                  borderRadius='5'
                  bg={bg}
                  color={color}
                  border='gray.200'
                  {...props.register('userRoleOption')}
                >
                  {myRoleOptions.map((r) => (
                    <option value={r.Id ?? r.id}>{r.name}</option>
                  ))}
                </Select>
              </FormControl>
            )}

            {myRoleOptions.length === 0 && <FormControl></FormControl>}
            <FormControl isInvalid={isInvalid} onChange={handleChange1}>
              <InputGroup>
              <FloatingLabelInput
                placeholder='Password'
                isRequired={true}
                {...props.register('password')}
                type={showPass1 ? 'text' : 'password'}
                value={pass1}
                isDisabled={props.userId !== undefined && props.userId !== ''}
              />
              <InputRightElement>
              <Button variant='link' onClick={toggleShowPass1} aria-label={showPass1 ? 'Hide password' : 'Show password'}>
                {showPass1 ? <ViewOffIcon /> : <ViewIcon />}
              </Button>
              </InputRightElement>
              </InputGroup>
              <FormErrorMessage color="black">{strengthMessage}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={pass1 !== pass2 || isInvalid} onChange={handleChange2}>
              <InputGroup>
              <FloatingLabelInput
                placeholder='Re-type Password'
                isRequired={true}
                {...props.register('passwordCopy')}
                type={showPass2 ? 'text' : 'password'}
                value={pass2}
                isDisabled={props.userId !== undefined && props.userId !== ''}
              />
              <InputRightElement>
              <Button variant='link' onClick={toggleShowPass2} aria-label={showPass2 ? 'Hide password' : 'Show password'}>
                {showPass2 ? <ViewOffIcon /> : <ViewIcon />}
              </Button>
              </InputRightElement>
              </InputGroup>
              <FormErrorMessage color="black">{pass1 !== pass2 ? 'Passwords must match.' : strengthMessage}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <Button
                isLoading={props.isLoading}
                leftIcon={<AddIcon />}
                variant='mojoPrimary'
                size='sm'
                display={
                  props.userId === undefined || props.userId === ''
                    ? 'none'
                    : 'flex'
                }
                onClick={() => {
                  setRoleOptions([]);
                  props.resetForm();
                }}
              >
                ADD NEW USER
              </Button>
            </FormControl>
            <FormControl>
              <Flex justify='right'>
                <Button
                  leftIcon={
                    props.userId === undefined || props.userId === '' ? (
                      <AddIcon />
                    ) : (
                      <EditIcon />
                    )
                  }
                  type='submit'
                  isDisabled={false}
                  variant='mojoPrimary'
                  isLoading={props.isLoading}
                  size='sm'
                  loadingText='Submitting'
                >
                  {props.userId === undefined || props.userId === ''
                    ? 'ADD NEW USER'
                    : 'UPDATE USER'}
                </Button>
              </Flex>
            </FormControl>
          </Grid>
        </Box>
      </Card>
    </>
  );
}